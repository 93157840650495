import React, { Component } from 'react';
import { Nav, OverlayTrigger, Popover } from 'react-bootstrap';

class Status extends Component
{
	constructor(props)
	{
		super(props);
		this.state = {
			status: "",
			message: "",
			icon: ""
		};
		this.element = React.createRef();
	}

	componentDidMount() {
		this.fetchStatus();
		this.pollStatusInterval = setInterval(
			() => this.fetchStatus(),
			15000
		);
	}

	componentWillUnmount() {
		clearInterval(this.pollStatusInterval);
	}

	updateStatus(data) {
		let status, message,icon;

		switch(data.status.indicator){
			case "none":
				status = data.status.indicator;
				message = "All Systems Operational";
				icon = "fa-check-circle";
				break;
			case "maintenance":
				status = data.status.indicator;
				message = "Maintenance";
				icon = "fa-exclamation-triangle";
				break;
			case "minor":
				status = data.status.indicator;
				message = "Partial System Outage";
				icon = "fa-exclamation-triangle";
				break;
			case "major":
				status = data.status.indicator;
				message = "Major Service Outage";
				icon = "fa-times-circle";
				break;
			case "critical":
				status = data.status.indicator;
				message = "Critical Service Outage";
				icon = "fa-times-circle";
				break;
			default:
		}

		let newState = {
			status: status,
			message: message,
			icon: icon
		};
		this.setState(newState);
	}

	fetchStatus()
	{
		let status = new window.StatusPage.page({ page : "3268r17nsynt" });
		status.status({success: (data) => { this.updateStatus(data) }});
	}

	render()
	{
		return (
			<a href="https://wowza-clearcaster.statuspage.io/">
				<OverlayTrigger
					trigger={["hover","focus"]}
					placement="bottom"
					overlay={
						<Popover id="status-popover">
							<Popover.Content className={`status-${this.state.status}`}>
								{this.state.message}
							</Popover.Content>
						</Popover>
					}
				>
					<Nav.Item className={`status-${this.state.status}`}>
						<i className={`status-icon fa ${this.state.icon}`}></i>
					</Nav.Item>
				</OverlayTrigger>
			</a>
		);
	}

}

export default Status;